/**
*
* -----------------------------------------------------------------------------
*
* Template : Workplace Safety - Online Education React Template
* Author : reacthemes
* Author URI : https://reactheme.com/
*
* ------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
@import "animations";
@import "keyframes";
@import "menu";
@import "design";
@import "responsive";
@import "custom-spacing";
@import "../fonts/flaticon";
@import "../fonts/elegant-icon";
@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify {
  z-index: 1000 !important;
  position: absolute;
  top: 0;
  right: 0;
}
